
import { Component, Vue } from 'vue-property-decorator'
import { DataList } from '@/types/workcard'

@Component({
  name: 'WordCardList'
})
export default class List extends Vue {
  private tableData: DataList[] =[]

  private info: {projectId: string}= {
    projectId: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
  }

  // 重新获取数据
  activated () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerProjectByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'workcardDetailMap',
      params: { projectId: id }
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }
}
